import g2logo from 'img/companyLogo/g2logo.png';
import getapplogo from 'img/companyLogo/getapp-logo.svg';
import capterralogo from 'img/companyLogo/capterraLogo.svg';
import getappBlack from 'img/companyLogo/getappBlack.svg';
import g2Black from 'img/companyLogo/g2Black.png';
import capterralogoBlack from 'img/companyLogo/capterraBlack.svg';

export const customers = [
  {
    key: 2,
    logo: capterralogo,
    logoBlack: capterralogoBlack,
    mark: 4.6,
    link: 'https://www.capterra.com/p/182661/LiveSession/',
    title: 'Capterrra',
  },
  {
    key: 1,
    logo: g2logo,
    logoBlack: g2Black,
    mark: 4.7,
    link: 'https://www.g2.com/products/livesession/reviews',
    title: 'G2',
  },
  {
    key: 3,
    logo: getapplogo,
    logoBlack: getappBlack,
    mark: 4.6,
    link: 'https://www.getapp.com/business-intelligence-analytics-software/a/livesession/',
    title: 'GetApp',
  },
];
